@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
}

a {
    color: white;
}

.App {
    background-color: #002d4e;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: max(calc(10px + 1vmin), 18px);
    color: white;
    padding: max(3vw, 40px) 5vw;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.3rem;
}

.App-logo {
    width: 60vw;
    pointer-events: none;
    margin-bottom: 20px;
}

.App-header {
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.content {
    max-width: 100%;
    width: 100%;
    padding-bottom: 100px;
}

.content a {
    color: white;
    text-decoration: none;
}

.content li {
    list-style-type: none;
}

ul.bulleted {
    list-style-type: circle;
    padding-left: 0;
}

a.sponsor {
    text-decoration: underline;
}

a.email {
    text-decoration: underline;
    display: block;
    margin-top: 10px;
}

iframe {
    border: 0;
}

.workouts ul {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    padding: 0;
}

.workouts-video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
}

.workouts li {
    padding: 1vw;
    position: relative;
    box-sizing: border-box;
    width: calc(100% / 3);
}

@media screen and (max-width: 992px) {
    .workouts li {
        box-sizing: border-box;
        width: calc(100% / 2);
    }
}

@media screen and (max-width: 576px) {
    .workouts li {
        box-sizing: border-box;
        width: 100%;
    }
}

.workouts li iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.user-name {
    font-weight: bold;
}

.content-about-section {
    margin-bottom: 60px;
}

.content-about-sub-section {
    margin-bottom: 40px;
}

.content-about-question {
    font-weight: bold;
}

.content-prizes-section {
    margin-bottom: 60px;
}

.content-library-section {
    margin-bottom: 60px;
}

.content-library-section ul {
    padding-left: 0;
}

.content-library-section li {
    margin-bottom: 10px;
}

.content-library-section li a {
    margin-left: 10px;
}

.content-library-section li a:hover {
    text-decoration: underline;
}

.content-library-my-vitals {
    width: 60vw;
    max-width: 600px;
}

.content-workouts-section {
    margin-bottom: 60px;
}

.content-home {
    text-align: center;
}

.logout-link {
    margin-top: 40px;
}

@media screen and (max-width: 992px) {
    .App-logo {
        width: 80vw;
    }
}

@media screen and (max-width: 576px) {
    .App-logo {
        width: 100vw;
    }

    .App {
        padding: max(2vw, 20px) 5vw;
    }

    .content-library-my-vitals {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    a.email {
        font-size: 16px;
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .App {
        font-size: 18px;
        padding: 40px 5vw;
    }
}

@supports (-ms-accelerator: true) {
    /* IE Edge 12+ CSS styles go here */
    .App {
        font-size: 18px;
        padding: 40px 5vw;
    }
}
