.vitals-title {
  margin-bottom: 40px;
}

.vitals-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 720px;
}
.vitals-form fieldset {
  margin-bottom: 40px;
  width: 100%;
  border: 0;
}

.vitals-fieldset legend {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.fieldset-photos .form-field {
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.fieldset-photos .form-field .form-field-photo-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: 50%;
}

.fieldset-photos .form-field img {
  width: 100%;
}

.form-field label {
  margin-bottom: 6px;
}
.form-field-input-container {
  display: flex;
  align-items: center;
}

.form-field-multi-input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-field-multi-input-container div {
  display: flex;
  align-items: center;
  min-width: 45%;
}

.form-field .unit {
  font-size: 0.8rem;
  margin-left: 3px;
}

.form-field input[type='text'] {
  width: 100%;
  line-height: 1.5rem;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 1rem;
  -webkit-appearance: none;
  border: none;
}

.form-field input[type='text']:disabled {
  color: #000000;
  background-color: #c0c0c0;
}

.form-field input[type='text']:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(221, 220, 231, 0.4);
}

.form-field textarea {
  width: 100%;
  border-radius: 5px;
  line-height: 1.2rem;
  font-size: 1rem;
  padding: 6px 10px;
  -webkit-appearance: none;
  border: none;
}

.form-field textarea:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(221, 220, 231, 0.4);
}

.form-field textarea:disabled {
  color: #000000;
  background-color: #c0c0c0;
}

.fieldset-fitness .divider {
  margin: 0 10px;
}

.fieldset-location select {
  width: 100%;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  height: 42px;
  text-align: center;
}

.form-field select:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(221, 220, 231, 0.4);
}

.form-field select:disabled {
  color: #000000;
  background-color: #c0c0c0;
  opacity: 0.5;
}

.icon-disabled path {
  fill: #c0c0c0;
}

@media screen and (max-width: 576px) {
  .form-field-multi-input-container {
    flex-direction: column;
  }
  .form-field-multi-input-container div {
    margin-bottom: 10px;
    width: 100%;
  }

  .fieldset-photos .form-field {
    flex-wrap: wrap;
  }

  .fieldset-photos .form-field .form-field-photo-div {
    padding-bottom: 10px;
    width: 100%;
  }

  .fieldset-photos .form-field.no-photo .form-field-photo-div {
    padding: 0 0 10px 0;
    width: 50%;
  }
}

input[type='submit'] {
  -webkit-appearance: none;
  font-size: 1.2rem;
  background-color: #ff671f;
  color: white;
  border: 0;
  padding: 0.8em 1em;
  border-radius: 5px;
  width: 100%;
  opacity: 1;
  margin-bottom: 100px;
}

input[type='submit']:disabled {
  -webkit-appearance: none;
  opacity: 0.5;
}

input[type='submit']:focus {
  -webkit-appearance: none;
  outline: none;
  box-shadow: 0 0 0 3px rgba(221, 220, 231, 0.4);
}

input[type='file'] {
  display: none;
}

@media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .fieldset-photos .form-field {
    display: block;
  }

  .fieldset-photos .form-field .form-field-photo-div {
    display: inline-block;
    width: 100%;
  }
}
