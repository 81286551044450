nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.menu {
  display: flex;
  flex-direction: row;
}

.menu a {
  padding: 0 16px;
}

nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

nav span {
  font-size: min(calc(10px + 1vmin), 16px);
  margin-top: 10px;
}

nav {
  font-size: calc(16px + 1vmin);
}

nav img {
  max-height: 60px;
}

nav a {
  color: white;
}

@media screen and (max-width: 576px) {
  nav img {
    max-height: 40px;
  }
}

@media screen and (max-width: 750px) {
  nav {
    flex-direction: column;
  }

  .menu {
    margin-top: 30px;
    justify-content: space-between;
    width: 100%;
  }

  .menu a {
    padding: 0 0;
  }
}

@media screen and (max-width: 460px) {
  .menu {
    margin-top: 10px;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }

  .menu a {
    text-align: center;
    /* padding-top: 20px; */
    /* flex: 1 1 75px; */
    padding: 20px 10px;
  }
}

@media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  nav span {
    font-size: 16px;
    margin-top: 10px;
  }
}

@supports (-ms-accelerator: true) {
  /* IE Edge 12+ CSS styles go here */
  nav span {
    font-size: 16px;
    margin-top: 10px;
  }
}
